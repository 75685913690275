.component {
  padding: var(--size-30) 0;
  color: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--size-40);

  @media (--viewport-md) {
    padding: var(--size-40) 0;
  }

  & > .logoLayout {
    width: 200px;

    @media (--viewport-md) {
      width: 240px;
      margin-top: calc(-1 * var(--size-5));
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  gap: var(--size-20);
}
