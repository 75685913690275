.component {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns:
    minmax(var(--gutter-responsive), 1fr)
    minmax(0, var(--size-container-max-width-md))
    minmax(var(--gutter-responsive), 1fr);

  & > * {
    grid-row: 1;
    min-width: 0;
  }

  & > .content {
    grid-column: 2;
  }

  & > .background {
    grid-column: 2 / -1;
    justify-self: end;
    z-index: -1;
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--size-40) var(--size-20);

  @media (--viewport-md) {
    padding: var(--size-60) var(--size-100);
  }

  & > .heading {
    margin-bottom: var(--size-10);
  }

  & > .text {
    margin-bottom: var(--size-30);
  }
}

.background {
  background: var(--gradient-to-bottom-right);
  border-top-left-radius: var(--border-radius-22);
  border-bottom-left-radius: var(--border-radius-22);
}
