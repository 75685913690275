.componentABase,
.componentButtonBase {
  padding: 0 var(--size-25);
  height: 58px !important;
  display: inline-flex;
  gap: var(--size-10);
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-18);
  border-radius: var(--border-radius-30);
  transition: background-position var(--duration-600) var(--ease-out-quad);
  background-size: 200% 100%;

  &:hover,
  &:focus {
    background-position: 50% 0;
  }
}

.componentPrimary {
  background-image: linear-gradient(45deg, var(--color-blue-300) 0%, var(--color-blue-300) 50%, var(--color-blue-300) 50%, var(--color-blue-500) 100%);
}

.componentSecondary {
  background-image: linear-gradient(45deg, var(--color-blue-500) 0%, var(--color-blue-500) 50%, var(--color-blue-500) 50%, var(--color-blue-300) 100%);
}

.componentGhost {
  border: 1px solid var(--color-white);
  background-image: linear-gradient(45deg, rgba(var(--color-rgb-blue-500), 0) 0%, rgba(var(--color-rgb-blue-500), 0) 50%, rgba(var(--color-rgb-blue-500), 0) 50%, var(--color-blue-500) 100%);
}

.icon {
  & > * {
    width: 18px;
    height: 18px;
  }
}
