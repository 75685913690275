.componentBase {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-18);

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  & > .icon {
    margin-right: var(--size-6);
    width: var(--size-24);
    height: var(--size-24);
  }
}

.componentWithoutUnderline {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.componentWithUnderline {
  position: relative;

  &::after {
    content: '';
    background-color: var(--color-blue-300);
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    transform-origin: right;
    transition: transform var(--duration-600) var(--ease-out-expo);
    will-change: transform;
  }

  &:hover,
  &:focus {
    text-decoration: none;

    &::after {
      transform: translateX(-50%) scaleX(1);
      transition: transform var(--duration-600) var(--ease-out-expo);
      transform-origin: left;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
}
