.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-white);
  border-radius: var(--border-radius-16);
  padding: var(--size-20);
  row-gap: var(--size-20);

  @media (--viewport-lg) {
    padding: var(--size-20) var(--size-40);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  & > .actions {
    flex-shrink: 0;
  }
}

.content {
  color: var(--color-blue-900);
  text-align: left;

  @media (--viewport-lg) {
    padding-right: var(--size-10);
  }
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-10);
}

.settings {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-900);

  @media (--viewport-md) {
    justify-content: flex-end;
  }
}
