.page {
  & > .hero {
    margin-bottom: var(--size-40);
  }
}

.componentContent {
  & > .blocks {
    &:first-of-type {
      margin-top: var(--size-80);
      margin-bottom: var(--size-80);
    }

    &:not(:first-of-type) {
      margin-top: var(--size-60);
      margin-bottom: var(--size-60);
    }
  }

  & > .imageGrid {
    margin-top: var(--size-80);
    margin-bottom: var(--size-60);
  }

  & > .jobCards {
    margin-top: var(--size-80);
    margin-bottom: var(--size-80);
  }
}
