.component {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;

  & > .imageGrid {
    z-index: -1;
    order: -1;
  }

  & > .container {
    margin-top: calc(-1 * var(--size-30));

    @media (--viewport-md) {
      margin-top: calc(-1 * var(--size-60));
    }
  }
}

.content {
  text-align: center;

  & > :not(:last-child) {
    margin-bottom: var(--size-30);
  }
}
