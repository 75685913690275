.component {
  display: grid;
  grid-template-areas: 'cell';
  position: relative;
  z-index: 0;

  & > * {
    grid-area: cell;
  }

  & > .imageContainer {
    height: max(100vh, 700px);
  }

  & > .textContainer {
    align-self: end;
    z-index: 2;
    margin-bottom: var(--size-70);

    @media (--viewport-md) {
      margin-bottom: var(--size-50);
    }
  }

  & > .gradient {
    z-index: 1;
    align-self: end;
    width: 100%;
    height: 25%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (--viewport-md) {
    padding-left: 0;
    padding-right: 0;
  }

  & > .heading {
    margin-bottom: var(--size-10);
  }

  & > .scrollIndicator {
    margin-top: var(--size-60);
  }
}

.imageContainer {
  opacity: 0.8;

  &.imageDesktop {
    display: none;

    @media (--viewport-md) {
      display: block;
    }
  }

  &.imageMobile {
    display: block;

    @media (--viewport-md) {
      display: none;
    }
  }

  & > .image {
    width: 100%;
    height: 100%;
  }
}

.gradient {
  background-image: var(--gradient-to-top);
}
