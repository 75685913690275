.component {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns:
    minmax(var(--gutter-responsive), 1fr)
    minmax(0, var(--size-container-max-width-lg))
    minmax(var(--gutter-responsive), 1fr);
  grid-template-rows: 60px repeat(2, auto) 80px;
  gap: var(--size-20);

  & > * {
    min-width: 0;
  }

  & > .header {
    grid-column: 2;
    grid-row: 1 / span 2;
    margin: 0 auto var(--size-20);
  }

  & > .jobs {
    grid-column: 2;
    grid-row: 3 / span 1;
  }

  & > .ctaButton {
    margin: 0 auto;
    align-self: end;
    grid-column: 2;
    grid-row: 4;
  }

  &::after {
    content: '';
    z-index: -1;
    grid-column: 2 / -1;
    grid-row: 2 / span 2;
    justify-self: end;
    width: calc(100% - var(--size-50));
    height: 50%;
    min-height: 100%;
    background: var(--gradient-to-bottom-right);
    border-top-left-radius: var(--border-radius-22);
    border-bottom-left-radius: var(--border-radius-22);
    transform: translateY(calc(-1 * var(--size-40)));

    @media (--viewport-md) {
      width: calc(100% - 130px);
      height: calc(100% - 60px);
    }
  }
}

.header {
  text-align: center;
  white-space: pre-line;
}

.containerHeading {
  text-align: center;
}

.jobs {
  --job-count: 3;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--size-10);

  @media (--viewport-md) {
    flex-direction: row;
  }

  &.shouldCenterJobs {
    justify-content: center;
  }

  & > .job {
    width: 100%;
    flex-grow: 0;
    max-width: 500px;

    @supports not (gap: 1px) {
      margin-right: var(--size-10);
      margin-bottom: var(--size-10);
    }

    @media (--viewport-md) {
      flex: 0 0 calc(100% / min(var(--job-count), 3) - var(--size-10));
    }
  }
}

.job {
  & > * {
    height: 100%;
  }
}

.componentJobCard {
  background-color: var(--color-white);
  color: var(--color-blue-500);
  padding: var(--size-20);
  font-size: var(--font-size-24);
  line-height: 1.1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: var(--border-radius-10);
  position: relative;
  transition: transform var(--duration-400) var(--ease-out-quad);

  &:hover,
  &:focus {
    transform: scale(1.04);

    & .heading {
      color: var(--color-blue-300);
    }

    & .link {
      background-color: var(--color-blue-300);
      color: var(--color-white);
    }
  }

  & > .info {
    &.relativeToParent {
      position: static;
    }
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.heading {
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-400);
  transition: color var(--duration-250) linear;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  & > .link {
    flex-shrink: 0;
    width: 38px;
    height: 38px;

    &.relativeToParent {
      position: static;
    }
  }
}

.location {
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-18);
  font-weight: var(--font-weight-400);
  display: flex;
  align-items: center;
  color: var(--color-blue-300);

  & > :not(:last-child) {
    margin-right: var(--size-5);
  }
}

.locationLabel {
  color: var(--color-blue-500);
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-blue-300);
  border-radius: 50%;
  position: relative;
  transition: color var(--duration-150) linear, background-color var(--duration-250) linear;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.icon {
  & > * {
    width: 15px;
    height: 15px;
  }
}
