.backgroundVideoContainer {
  position: relative;
  z-index: 0;
  display: grid;
  place-items: center;
  aspect-ratio: 16 / 9;

  @supports not (aspect-ratio: 16 / 9) {
    &::before {
      content: "";
      position: absolute;
      padding-top: 56.25%;
      inset: 0;
    }
  }

  & > * {
    grid-column: 1;
    grid-row: 1;
  }

  & > .videoButton {
    margin: 0 var(--size-20);
    z-index: 1;
  }

  & > .backgroundVideo {
    width: 100%;
    height: 100%;
  }
}

.backgroundVideo {
  object-fit: cover;
}

.videoFull {
  display: none;

  &.isActive {
    display: unset;
  }
}
