._rootComponent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overscroll-behavior: none;

  & > * {
    height: 100%;
  }
}

.component {
  background-color: var(--color-blue-500);
  overscroll-behavior: contain;
  overflow-y: auto;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  position: relative;

  & > .top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  & > .inner {
    width: calc(100% - (var(--gutter-responsive) + var(--size-15)));
  }
}

.top {
  padding: var(--size-30) var(--gutter-responsive);
  color: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--size-40);

  & > .logo {
    width: 240px;
  }

  & > .button {
    height: 54px;
    width: 54px;
    flex-shrink: 0;
  }
}

.inner {
  background: var(--gradient-to-top-left);
  border-radius: 0 0 var(--border-radius-22) 0;
  padding: var(--size-140) var(--size-15) 0 calc(var(--gutter-responsive) + var(--size-15));
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--size-50) var(--gutter-responsive);
  color: var(--color-white);
}

.componentCloseButton {
  border: 1px solid var(--color-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient-to-left);
  background-size: 150%;
}
