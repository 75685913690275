.component {
  position: relative;
  z-index: 0;
  overflow: hidden;

  & > .imageContainer {
    height: 700px;
    width: 100%;

    @media (--viewport-md) {
      height: 900px;
    }
  }

  & > .content {
    position: absolute;
    bottom: var(--size-100);
    z-index: 1;
    width: 100%;
  }

  & > .gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25%;
  }
}

.inner {
  text-align: center;

  & > :not(:last-child) {
    margin-bottom: var(--size-10);
  }
}

.text {
  font-size: var(--font-size-28);
  font-size: var(--font-size-28-38);
  line-height: var(--line-height-text);
}

.imageContainer {
  opacity: 0.8;
  overflow: hidden;

  & > .image {
    width: 100%;
    height: 100%;
  }
}

.image {
  &.desktopVersion {
    display: none;

    @media (--viewport-md) {
      display: block;
    }
  }

  &.mobileVersion {
    display: block;

    @media (--viewport-md) {
      display: none;
    }
  }
}

.gradient {
  background-image: var(--gradient-to-top);
}
