.componentTable {
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
  text-align: left;
  font-size: var(--font-size-12);

  & > * {
    width: 100%;
  }
}

.table {
  word-break: break-word;
  border-collapse: separate;
  border-spacing: var(--size-10) 0;
}

.colgroup {
  & > * {
    min-width: 100px;
  }
}
