.componentMainContent {
  font-size: var(--font-size-16-22);
  overflow: auto; /* Disable margin collapse */

  & > * {
    margin-top: var(--size-20);
    margin-bottom: var(--size-20);
  }

  & > .heading {
    margin-top: var(--size-30);
    margin-bottom: var(--size-15);

    @media (--viewport-md) {
      margin-top: var(--size-90);
    }
  }

  & > .listOrdered,
  & > .listUnordered {
    margin-top: var(--size-20);
    margin-left: 1em;
  }

  & > .subheading {
    margin-top: var(--size-30);
    margin-bottom: var(--size-15);

    @media (--viewport-md) {
      margin-top: var(--size-90);
    }
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.paragraph {
  line-height: 1.5;
}

.heading,
.subheading {
  font-size: var(--font-size-32-42);
  line-height: 1.2;

  & > strong {
    font-weight: inherit;
  }
}

.listOrdered {
  list-style-type: decimal;
  list-style-position: outside;
}

.listUnordered {
  list-style-type: disc;
  list-style-position: outside;
}

.paragraphCookieBar {
  font-size: var(--font-size-14);
}

.link {
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.strong {
    font-weight: var(--font-weight-400);
  }
}
