.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-15);

  & > .icon {
    width: 42px;
    height: 42px;

    @media (--viewport-md) {
      width: 64px;
      height: 64px;
    }
  }
}

.heading {
  line-height: 1;
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-18);
}

.icon {
  backdrop-filter: blur(2px);
  overflow: hidden;
  border: 2px solid var(--color-blue-300);
  border-radius: 50%;
  padding: var(--size-10);
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerIcon {
  animation: arrowAnimation var(--duration-3000) infinite;

  & > * {
    width: 28px;
    height: 28px;
  }
}

@keyframes arrowAnimation {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  16% {
    opacity: 0;
    transform: translateY(100%);
  }

  32% {
    opacity: 0;
    transform: translateY(-100%);
  }

  66% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
