.component {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (3 / 2));
    height: 0;
  }

  & > .video,
  & > .poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important; /* Prevent override by react-player */
    height: 100% !important; /* Prevent override by react-player */
  }

  & > .poster {
    z-index: 1;
  }
}

.poster {
  pointer-events: none !important; /* Prevent override by react-player */
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .posterImage {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.posterImage {
  & > * {
    width: 100%;
    height: 100%;
  }
}
