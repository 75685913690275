.component {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns:
    minmax(var(--gutter-responsive), 1fr)
    minmax(0, calc(var(--size-container-max-width-sm)))
    50px
    minmax(var(--gutter-responsive), 1fr);

  & > * {
    grid-row: 1;
    min-width: 0;
  }

  & > .imageContainer {
    grid-column: 2;
  }

  & > .background {
    grid-column: 1 / span 2;
    z-index: -1;
    width: calc(100% - var(--size-50));

    @media (--viewport-md) {
      width: calc(100% - var(--size-100));
    }
  }
}

.imageContainer {
  padding: var(--size-30) 0;

  & > * {
    width: 100%;
  }
}

.background {
  background: var(--gradient-to-bottom-left);
  border-top-right-radius: var(--border-radius-22);
  border-bottom-right-radius: var(--border-radius-22);
}
