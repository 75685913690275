.component {
  overflow: hidden;

  & > * {
    height: 100%;
  }
}

.slider {
  display: flex;
  position: relative;
  z-index: 0;

  & > .controls {
    position: absolute;
    left: 0;
    bottom: var(--size-30);
    width: 100%;
    z-index: 1;

    @media (--viewport-md) {
      width: 50%;
      left: 50%;
    }
  }

  & > .progressBar {
    position: absolute;
    right: var(--size-30);
    left: var(--size-30);
    top: var(--size-30);
    width: 85%;
    z-index: 1;

    @media (--viewport-md) {
      width: 45%;
      left: auto;
    }
  }
}

.componentProgressBar {
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    flex-grow: 1;
    height: 4px;
  }

  & > :not(:last-child) {
    margin-right: var(--size-5);
  }
}

.progress {
  background-color: var(--color-white);
  transition: background-color var(--duration-600) var(--ease-out-expo);

  &.isActive {
    background-color: var(--color-blue-300);
  }
}

.controls {
  padding-left: var(--size-30);
  padding-right: var(--size-30);

  @media (--viewport-md) {
    padding-left: var(--size-100);
    padding-right: var(--size-100);
  }
}

.componentSlide {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  z-index: 0;
  position: relative;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .content {
    z-index: 2;
    margin-top: calc(-1 * var(--size-80));

    @media (--viewport-md) {
      margin-top: 0;
    }
  }

  & > .imageContainer {
    height: 60vh;
    order: -1;
    z-index: 1;

    @media (--viewport-md) {
      height: 100%;
    }
  }

  & > * {
    width: 100%;

    @media (--viewport-md) {
      width: 50%;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: var(--size-30);
  padding-right: var(--size-30);
  padding-bottom: var(--size-100);
  text-align: center;

  & > .contentInner {
    max-width: var(--size-container-max-width-sm);
  }
}

.contentInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .heading {
    margin-bottom: var(--size-15);
  }

  & > .slideIcon {
    order: -1;
    margin-bottom: var(--size-20);
    width: 75px;
  }
}

.text {
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-22);
  text-align: center;
}

.slideIcon {
  color: var(--color-blue-300);

  & > * {
    width: 100%;
    height: auto;
  }
}

.icon {
  & > * {
    width: 100%;
    height: auto;
  }
}

.imageContainer {
  position: relative;
  z-index: 0;

  & > .image {
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  & > .gradient {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: 25%;

    @media (--viewport-md) {
      top: 0;
      bottom: 0;
      width: 25%;
      height: 100%;
    }
  }
}

.arrow {
  color: var(--color-white);

  &.disabled {
    opacity: 20%;
  }
}

.gradient {
  background-image: var(--gradient-to-top);

  @media (--viewport-md) {
    background-image: var(--gradient-from-transparent-to-right);
  }
}

.componentControls {
  color: var(--color-white);
  display: flex;
  justify-content: space-between;

  & > * {
    flex-shrink: 0;
  }
}

.closeButton {
  color: var(--color-white);

  & > .iconContainer {
    width: 44px;
    height: 44px;
  }

  &:hover,
  &:focus {
    & > .iconContainer {
      transform: scale(1.1);
      background-color: var(--color-blue-300);
    }
  }
}

.iconContainer {
  overflow: hidden;
  border: 2px solid var(--color-blue-300);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform var(--duration-400) var(--ease-out-quad), background var(--duration-600) linear;
}

.iconCross {
  & > * {
    width: 20px;
    height: 20px;
  }
}
