.page {
  & > .hero {
    margin-bottom: var(--size-40);
  }
}

.blocks {
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);
}

.softCta {
  padding-bottom: var(--size-40);
}

.ctaButton {
  padding-bottom: var(--size-40);
  text-align: center;
}

.visualWithQuoteFact {
  padding-top: var(--size-60);
  padding-bottom: var(--size-60);
}

.disciplineAreaList,
.ctaBenefits,
.jobCards {
  padding-top: var(--size-40);
  padding-bottom: var(--size-100);
}
