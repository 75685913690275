._rootComponent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  margin: auto;

  & > * {
    height: 100%;
  }
}

.component {
  position: relative;
  z-index: 0;
  display: flex;
  color: var(--color-white);
  opacity: 0;
  transition: opacity var(--duration-400) var(--ease-in-out-circ);

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  & > .backdrop {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .close {
    position: absolute;
    right: var(--size-15);
    top: var(--size-15);
    z-index: 1;

    @media (--viewport-md) {
      right: var(--size-30);
      top: var(--size-30);
    }
  }

  & > .inner {
    width: 100%;
    margin: auto;

    @media (--viewport-md) {
      width: 90%;
    }

    @media (--viewport-lg) {
      width: 80%;
    }
  }
}

.inner {
  background-color: var(--color-gray-900);
  transform-origin: bottom left;

  & > * {
    height: 100%;
  }
}

.backdrop {
  background-color: var(--color-gray-900);
}

.componentCloseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & > * {
    width: 24px;
    height: 24px;

    @media (--viewport-md) {
      width: 32px;
      height: 32px;
    }
  }
}
