
.component {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns:
    minmax(var(--gutter-responsive), 1fr)
    minmax(0, var(--size-container-max-width-md))
    minmax(var(--gutter-responsive), 1fr);

  & > * {
    grid-row: 1;
    min-width: 0;
  }

  & > .content {
    grid-column: 2;
  }

  & > .background {
    grid-column: 2 / -1;
    justify-self: end;
    z-index: -1;
    width: calc(100% - var(--size-50));

    @media (--viewport-md) {
      width: calc(100% - var(--size-100));
    }
  }
}

.content {
  padding: var(--size-40) 0;

  & > .quote {
    margin-bottom: var(--size-30);
  }

  & > .label {
    margin-left: var(--size-80);

    @media (--viewport-md) {
      margin-left: var(--size-140);
    }
  }
}

.quote {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-52);
  line-height: 1.3;

  &::before {
    content: '“';
  }

  &::after {
    content: '”';
  }
}

.label {
  font-size: var(--size-14);
  font-size: var(--font-size-14-18);
}

.background {
  background: var(--gradient-to-bottom-right);
  border-top-left-radius: var(--border-radius-22);
  border-bottom-left-radius: var(--border-radius-22);
}
