.component {
  display: flex;
  align-items: center;
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-18);
  color: var(--color-white);

  & > .iconContainer {
    flex-shrink: 0;
    margin-right: var(--size-15);
    width: 44px;
    height: 44px;
  }

  &:hover,
  &:focus {
    & > .iconContainer {
      transform: scale(1.1);
      background-color: var(--color-blue-300);
    }
  }
}

.iconContainer {
  overflow: hidden;
  border: 2px solid var(--color-blue-300);
  backdrop-filter: blur(2px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform var(--duration-400) var(--ease-out-quad), background var(--duration-600) linear;
}

.icon {
  & > * {
    width: 20px;
    height: 20px;
  }
}
