.component {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns:
    minmax(var(--gutter-responsive), 1fr)
    minmax(0, calc(var(--size-container-max-width-lg) - 50px))
    50px
    minmax(var(--gutter-responsive), 1fr);

  & > * {
    grid-row: 1;
    min-width: 0;
  }

  & > .content {
    grid-column: 2;
  }

  & > .background {
    grid-column: 1 / span 3;
    z-index: -1;
    width: 100%;
  }
}

.heading {
  text-align: center;
}

.content {
  padding: var(--size-50) 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewport-md) {
    padding: var(--size-100) 0;
  }

  & > .heading {
    margin-bottom: var(--size-70);

    @media (--viewport-md) {
      margin-bottom: var(--size-30);
    }
  }
}

.items {
  display: flex;
  flex-direction: column;
  gap: var(--size-30);

  @media (--viewport-md) {
    flex-direction: row;
    gap: var(--size-50);
  }

  & > .item {
    @media (--viewport-md) {
      width: calc(100% / 3);
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: var(--size-10);
  align-items: center;
  text-align: center;

  & > .text {
    max-width: 25ch;
  }
}

.itemHeading {
  white-space: nowrap;
}

.background {
  background: var(--gradient-to-bottom-left);
  border-top-right-radius: var(--border-radius-22);
  border-bottom-right-radius: var(--border-radius-22);
}
