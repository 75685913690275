.component {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-areas:
    'heading'
    'cell';

  & > .headingContainer {
    grid-area: heading;
    margin-bottom: var(--size-50);
  }

  & > .listContainer {
    grid-area: cell;
    width: 100%;
  }

  & > .imageContainer {
    position: sticky;
    z-index: -1;
    top: 0;
    grid-area: cell;
    align-self: start;
  }
}

.headingContainer {
  text-align: center;
}

.componentCrossFade {
  overflow: hidden;
  display: grid;
  grid-template-areas: 'cell';
  position: relative;
  z-index: 0;

  & > .navigation {
    position: absolute;
    right: var(--size-15);
    z-index: 1;
    top: 0;
    bottom: 0;

    @media (--viewport-md) {
      right: var(--size-350);
    }
  }

  & > .crossFadeItem {
    grid-area: cell;
    width: 100%;
    height: 100vh;
  }

  & > .preloadImage {
    grid-area: cell;
    width: 100%;
    height: 100vh;
  }

  & > .gradient {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 25%;
  }
}

.crossFadeItem {
  position: relative;

  & > .image {
    width: 100%;
    height: 100%;
  }
}

.preloadImage {
  pointer-events: none;
  opacity: 0;
}

.image {
  &.desktopVersion {
    display: none;

    @media (--viewport-md) {
      display: block;
    }
  }

  &.mobileVersion {
    display: block;

    @media (--viewport-md) {
      display: none;
    }
  }
}

.list {
  padding: 50vh 0;

  & > :not(:last-child) {
    margin-bottom: 50vh;
  }
}

.componentInitiative {
  position: relative;
  display: flex;
  align-items: center;

  & > .innerInitiative {
    width: 90%;

    &.relativeToParent {
      position: static;
    }
  }
}

.innerInitiative {
  position: relative;
  display: flex;
  flex-direction: column;

  & > .heading {
    margin-bottom: var(--size-10);
  }
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-22);

  &.relativeToParent {
    position: static;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & > .icon {
    order: -1;
    width: 42px;
    height: 42px;
    margin-right: var(--size-10);
    flex-shrink: 0;
  }
}

.icon {
  background-color: var(--color-blue-300);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerIcon {
  & > * {
    width: 18px;
    height: 18px;
  }
}

.gradient {
  background-image: var(--gradient-to-top);
}
