.component {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: var(--gutter-responsive) auto var(--gutter-responsive);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: var(--size-40);
  grid-template-areas:
    '.... text ....'
    '.... events ....';

  @media (--viewport-lg) {
    grid-template-columns: minmax(var(--gutter-responsive), 1fr) repeat(4, minmax(0, calc(var(--size-container-max-width-lg) / 4))) minmax(var(--gutter-responsive), 1fr);
    grid-template-rows: var(--size-70) auto auto;
    grid-row-gap: none;
    grid-template-areas:
      '.... .... .... .... ....  ....'
      '.... text text events events ....'
      '.... .... .... events events ....';
  }

  & > * {
    min-width: 0;
  }

  & > .textContainer {
    grid-area: text;
    width: calc(100% - 50px);

    @media (--viewport-lg) {
      width: 100%;
    }
  }

  & > .eventsContainer {
    grid-area: events;
  }

  & > .backgroundElement {
    grid-column: 1 / span 2;
    grid-row: 1 / span 3;
    z-index: -1;
    width: calc(100% - 50px);

    @media (--viewport-lg) {
      grid-column: 1 / span 4;
      grid-row: 1 / span 2;
      width: 100%;
    }
  }
}

.textContainer {
  padding-top: var(--size-40);
  padding-right: var(--size-40);

  @media (--viewport-lg) {
    padding-top: 0;
    padding-right: var(--size-70);
    padding-bottom: var(--size-70);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.eventsContainer {
  list-style: none;

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.backgroundElement {
  background: var(--gradient-to-bottom-left);
  border-top-right-radius: var(--border-radius-22);
  border-bottom-right-radius: var(--border-radius-22);
}
