.componentMd {
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-22);
  line-height: var(--line-height-text);
}

.componentLg {
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-26);
  line-height: var(--line-height-text);
}

.componentXl {
  font-size: var(--font-size-28);
  font-size: var(--font-size-28-42);
  line-height: var(--line-height-text);
}
