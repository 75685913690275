.component {
  & > .button {
    height: 54px;
    width: 54px;
  }
}

.button {
  border: 2px solid var(--color-blue-300);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
}

.componentNavigation {
  display: flex;
  flex-direction: column;
  color: var(--color-white);

  & > .navigationItem {
    margin-bottom: var(--size-50);
  }
}

.componentSubNav {
  & > .subNavigationItem {
    margin-top: var(--size-20);
    margin-left: var(--size-15);
  }
}

.navigationLink {
  font-size: var(--font-size-38);
}

.navigationHeading {
  font-size: var(--font-size-38);
}

.subNavigationLink {
  font-size: var(--font-size-28);
}
