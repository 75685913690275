.menu {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--size-30);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-400);
}

.button {
  display: flex;
  align-items: center;
  gap: var(--size-5);
}

.menuItem {
  position: relative;
  white-space: nowrap;

  & > .subMenu {
    margin-top: var(--size-10);
    position: absolute;
    top: auto;
    right: 0;
  }
}

.icon {
  color: var(--color-blue-300);
  transition: transform var(--duration-250) var(--ease-out-quad);
  transform: rotate(180deg);

  &.isActive {
    transform: rotate(0deg);
  }
}

.componentSubmenu {
  white-space: normal;
  overflow: hidden;
  transition: height var(--duration-250) var(--ease-out-quad);
  background-color: var(--color-white);
  border-radius: var(--border-radius-10);
}

.submenuItems {
  padding: var(--size-20) var(--size-40) var(--size-20) var(--size-30);
  font-size: var(--font-size-16);
  color: var(--color-blue-500);

  & > :not(:last-child) {
    margin-bottom: var(--size-10);
    width: 100%;
  }
}

.innerLink {
  --iconSize: 32px;

  position: relative;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    & > .linkIcon {
      transform: translateX(0);
      opacity: 1;
    }

    & > .linkText {
      transform: translateX(calc(var(--iconSize) + var(--size-5)));
    }
  }

  & > .linkIcon {
    width: var(--iconSize);
    height: var(--iconSize);
    flex-shrink: 0;
    left: 0;
    position: absolute;
  }
}

.linkIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-blue-300);
  border-radius: 50%;
  color: var(--color-white);
  transform: translateX(calc(-1 * (var(--iconSize) + var(--size-5))));
  opacity: 0;
  transition: transform var(--duration-250) var(--ease-out-quad),
    opacity var(--duration-250) linear;

  & > * {
    width: 16px;
    height: 16px;
  }
}

.linkText {
  padding-right: var(--iconSize);
  transition: transform var(--duration-250) var(--ease-out-quad);
  line-height: 1.1;
  text-decoration: none;
}
