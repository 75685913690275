.componentBase {
  line-height: 1;
  font-weight: inherit;
}

.componentSm {
  font-size: var(--font-size-24);
  font-size: var(--font-size-24-42);
}

.componentMd {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-42);
}

.componentLg {
  font-size: var(--font-size-38);
  font-size: var(--font-size-38-52);
}

.componentXl {
  font-size: var(--font-size-38);
  font-size: var(--font-size-38-72);
}

.componentXxl {
  font-size: var(--font-size-72);
  font-size: var(--font-size-72-112);
}
