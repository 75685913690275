.componentBase {
  --card-color: var(--color-black);
  --card-bg-color: var(--color-white);

  display: grid;
  column-gap: var(--size-25);
  padding: var(--size-30);
  background: var(--color-white);
  color: var(--color-blue-500);
  border-radius: var(--border-radius-10);
  transition: transform var(--duration-400) var(--ease-out-quad);
  position: relative;

  & > * {
    min-width: 0;
  }

  & > .mobileDateLayout {
    grid-area: mobile-date;
  }

  & > .dateLayout {
    grid-area: date;
  }

  & > .titleLayout {
    grid-area: title;
    position: static;
  }

  & > .calendarLayout {
    grid-area: calendar;
    height: 90px;
    width: 93px;
  }

  & > .locationLayout {
    grid-area: location;
  }

  & > .descriptionLayout {
    grid-area: description;
  }

  & > .buttonLayout {
    grid-area: button;
    position: static;
    align-self: center;
  }

  &:hover,
  &:focus {
    transform: scale(1.04);
    --card-color: var(--color-white);
    --card-bg-color: var(--color-blue-300);
  }
}

.compactGrid {
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto auto auto;
  grid-template-areas:
    'title button'
    'mobile-date button'
    'date button'
    'location button';

  @media (--viewport-md) {
    grid-template-columns: auto 1fr auto;
    grid-template-rows: var(--size-10) 1fr auto auto;
    grid-template-areas:
      'calendar .... button'
      'calendar title button'
      'calendar date button'
      'calendar location button';
  }

  & > .titleLayout {
    margin-bottom: var(--size-10);
  }
}

.detailedGrid {
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas:
    'title'
    'mobile-date'
    'date'
    'location'
    'description'
    'button';

  @media (--viewport-md) {
    grid-template-columns: auto max-content 1fr;
    grid-template-rows: var(--size-10) auto auto 1fr auto;
    grid-template-areas:
      'calendar .... ....'
      'calendar title title'
      'calendar date location'
      'calendar description description'
      '.... button button';
  }

  & > .titleLayout {
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-bottom: var(--size-5);
    }
  }

  & > .descriptionLayout {
    margin-top: var(--size-30);
    max-width: 42ch;
  }

  & > .buttonLayout {
    margin-top: var(--size-15);
  }
}

.componentChip,
.componentLinkTitle,
.componentTextTitle,
.componentCalendar,
.calendarDay,
.calendarMonth,
.componentLocation {
  line-height: 1;
}

.componentDescription {
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-18);
}

.componentChip {
  display: flex;
  align-items: center;
  font-size: var(--font-size-14);
  font-size: var(--font-size-14-16);
  font-weight: var(--font-weight-400);

  & > .iconLayout {
    width: 22px;
  }

  & > :first-child {
    margin-right: var(--size-5);
  }

  & > :last-child {
    margin-top: 3px;
  }
}

.componentTextTitle,
.componentLinkTitle {
  z-index: 0;
  position: relative;
  align-items: center;
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-26);
  font-weight: var(--font-weight-400);

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    inset: 0;
  }
}

.componentCalendar {
  align-items: center;

  & > .svgForeignObject {
    width: 77px;
    height: 55px;
  }
}

.svgCalendarPath {
  fill-rule: nonzero;
  fill: var(--color-blue-300);
}

.svgForeignObject {
  & > .calendarInner {
    height: 100%;
  }
}

.calendarInner {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-rows: 2fr 1fr;
  padding: 0.5rem 0;
  fill: var(--color-blue-500);
  font-family: var(--font-family-base);
}

.calendarDay {
  font-weight: var(--font-weight-400);
  text-align: center;
  font-size: var(--font-size-32);
}

.calendarMonth {
  text-align: center;
  font-size: var(--font-size-14);
}

.componentPseudoButton {
  display: inline-flex;
  align-items: center;
  gap: var(--size-10);
  position: relative;
  pointer-events: none;

  & > .buttonIconContainer {
    height: 38px;
    width: 38px;
  }
}

.buttonIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-blue-300);
  border-radius: 50%;
  position: relative;
  color: var(--card-color);
  background: var(--card-bg-color);
  transition:
    color var(--duration-150) linear,
    background-color var(--duration-250) linear;

  & > .icon {
    width: var(--size-20);
    height: var(--size-20);
  }
}
