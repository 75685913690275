.component {
  background: var(--gradient-to-bottom-right);
  border-radius: var(--border-radius-30);
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  position: relative;

  @media (--viewport-md) {
    flex-direction: row-reverse;
  }

  & > .content {
    width: 100%;

    @media (--viewport-md) {
      width: 50%;
    }
  }

  & > .singleGridLayout {
    width: 100%;

    @media (--viewport-md) {
      width: 50%;
    }
  }

  & > .singleGridVideoLayout {
    width: 100%;

    @media (--viewport-md) {
      width: 50%;
    }
  }

  & > .multipleGridLayout {
    margin-left: var(--size-25);
    margin-right: var(--size-25);
    margin-top: calc(-1 * var(--size-100));

    @media (--viewport-md) {
      margin-right: 0;
      margin-left: 0;
      margin-top: 0;
      position: absolute;
      top: calc(-1 * var(--size-70));
      left: calc(-1 * var(--size-70));
    }
  }
}

.content {
  padding: 0 var(--size-25) var(--size-25) var(--size-25);

  @media (--viewport-md) {
    padding: var(--size-70) var(--size-70) var(--size-70) 0;
  }

  @media (--viewport-xl) {
    padding: var(--size-100) var(--size-100) var(--size-100) 0;
  }

  & > .layoutHeading {
    margin-bottom: var(--size-15);

    @media (--viewport-md) {
      margin-bottom: var(--size-30);
    }
  }

  & > .buttons {
    margin-top: var(--size-25);

    @media (--viewport-md) {
      margin-top: var(--size-30);
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: var(--size-10);

  @media (--viewport-lg) {
    flex-direction: row;
  }
}

.componentMultipleGridImages,
.componentMultipleGridImageAndVideo {
  display: grid;
  grid-template-rows: minmax(auto, 85px) 1fr;
  grid-template-columns: minmax(200px, 1fr) 100px minmax(20px, auto);

  @media (--viewport-md) {
    grid-template-rows: minmax(auto, 155px) 1fr;
    grid-template-columns: minmax(200px, 1fr) 140px minmax(120px, auto);
  }

  @media (--viewport-lg) {
    grid-template-rows: minmax(auto, 155px) 1fr;
    grid-template-columns: minmax(200px, 1fr) 100px minmax(120px, auto);
  }

  & > .imageOne,
  & > .video {
    max-width: 250px;
    max-height: 210px;
    grid-column: 1 / 3;
    grid-row: 1 / 3;

    @media (--viewport-md) {
      max-width: 370px;
      max-height: 290px;
    }

    @media (--viewport-xl) {
      max-width: 480px;
      max-height: 400px;
    }
  }

  & > .imageTwo {
    max-width: 125px;
    max-height: 190px;
    grid-column: 2 / 4;
    grid-row: 2 / 3;

    @media (--viewport-md) {
      max-width: 180px;
      max-height: 300px;
    }

    @media (--viewport-xl) {
      max-width: 240px;
      max-height: 360px;
    }
  }
}

.componentSingleGridImage,
.componentSingleGridVideo {
  & > .imageSingleGrid,
  & > .video {
    width: 90%;
    margin: auto;
    max-height: 210px;
    margin-top: calc(-1 * var(--size-100));
    margin-bottom: var(--size-25);

    @media (--viewport-md) {
      margin-left: calc(-1 * var(--size-100));
      margin-bottom: 0;
      margin-top: 0;
      max-width: 610px;
      max-height: 360px;
      width: 100%;
      height: 100%;
    }
  }
}

.image,
.video {
  border-radius: var(--border-radius-22);
  overflow: hidden;

  & > * {
    width: 100%;
    height: 100%;
  }
}
