.component {
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: grid;
  grid-template-columns:
    repeat(2, var(--gutter-responsive))
    auto
    var(--gutter-responsive);
  grid-template-rows: 400px var(--size-40) auto;
  grid-template-areas:
    '.... ....    media   media'
    '.... ....    ....    ....'
    '.... content content ....';

  @media (--viewport-lg) {
    grid-template-columns:
      minmax(var(--gutter-responsive), 1fr)
      minmax(0, calc(var(--size-container-max-width-lg) / 2 - var(--size-20)))
      var(--size-40)
      minmax(0, calc(var(--size-container-max-width-lg) / 2 - var(--size-20)))
      minmax(var(--gutter-responsive), 1fr);
    grid-template-rows: 140px auto 70px;
    grid-template-areas:
      '.... ....    .... ....  ....'
      '.... content .... media media'
      '.... ....    .... media media';
  }

  & > * {
    min-width: 0;
  }

  & > .content {
    grid-area: content;
  }

  & > .mediaContainer {
    width: 100%;
    height: 100%;
    grid-area: media;
  }

  & > .background {
    z-index: -1;
    grid-column: 1 / span 3;
    grid-row: 3 / -1;

    @media (--viewport-lg) {
      grid-column: 1 / span 2;
      grid-row: 1 / span 2;
    }
  }
}

.content {
  padding: var(--size-40) var(--size-30) var(--size-40) 0;

  @media (--viewport-lg) {
    padding: var(--size-70) var(--size-40) var(--size-70) 0;
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.mediaContainer {
  display: grid;
  place-items: center;
  border-bottom-left-radius: var(--size-20);
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);

  & > * {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    height: 100%;
  }

  @media (--viewport-lg) {
    border-top-left-radius: var(--size-20);
  }
}

.background {
  background: var(--gradient-to-bottom-left);
  border-bottom-right-radius: var(--border-radius-22);
  border-top-right-radius: var(--border-radius-22);

  @media (--viewport-lg) {
    border-top-right-radius: 0;
    background: var(--gradient-to-top-left);
  }
}
