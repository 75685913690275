.component {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-areas:
    'heading'
    'cell';

  & > .containerLayout {
    grid-area: heading;
    margin-bottom: var(--size-50);
  }

  & > .listContainerLayout {
    grid-area: cell;
    width: 100%;
  }

  & > .crossFadeLayout {
    position: sticky;
    z-index: -1;
    top: 0;
    grid-area: cell;
    align-self: start;
  }
}

.headingContainer {
  text-align: center;
}

.componentCrossFade {
  overflow: hidden;
  display: grid;
  grid-template-areas: 'cell';
  position: relative;
  z-index: 0;

  & > .navigation {
    margin: 0 auto;
    grid-area: cell;
    z-index: 1;
    width: 100%;
    max-width: var(--container-max-width-md);
  }

  & > .crossFadeItem {
    grid-area: cell;
    width: 100%;
    height: 100vh;
  }

  & > .preloadImage {
    grid-area: cell;
    width: 100%;
    height: 100vh;
  }

  & > .gradient {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 25%;
  }
}

.crossFadeItem {
  position: relative;

  & > .image {
    width: 100%;
    height: 100%;
  }
}

.preloadImage {
  pointer-events: none;
  opacity: 0;
}

.image {
  &.desktopVersion {
    display: none;

    @media (--viewport-md) {
      display: block;
    }
  }

  &.mobileVersion {
    display: block;

    @media (--viewport-md) {
      display: none;
    }
  }
}

.list {
  padding: 50vh 0;

  & > :not(:last-child) {
    margin-bottom: 50vh;
  }
}

.listItem {
  padding: 0 var(--size-20) 0 0;
}

.componentDiscipline {
  position: relative;
  display: flex;
  align-items: center;

  & > .icon {
    order: -1;
    width: 38px;
    height: 38px;
    margin-right: var(--size-10);
    flex-shrink: 0;

    @media (--viewport-md) {
      width: 77px;
      height: 77px;
      margin-right: var(--size-20);
    }
  }

  & > .innerDiscipline {
    width: 90%;
  }
}

.innerDiscipline {
  position: relative;

  &.relativeToParent {
    position: static;
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-10);
  }
}

.heading {
  position: relative;

  &.relativeToParent {
    position: static;
  }
}

.link {
  position: relative;
  font-weight: var(--font-weight-400);

  &.relativeToParent {
    position: static;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -20%;
  }
}

.description {
  font-size: var(--font-size-22);
  font-size: var(--font-size-22-38);
  line-height: 1;
}

.icon {
  background-color: var(--color-blue-300);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    width: 22px;
    height: 22px;

    @media (--viewport-md) {
      width: 32px;
      height: 32px;
    }
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  & > * {
    margin-right: var(--size-10);
    height: min(10vh, 120px);
    width: 5px;
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-10);
  }
}

.componentNavigation {
  background-color: var(--color-white);
  opacity: 0.2;
  transition: opacity var(--ease-out-back-in) var(--duration-1500);

  &.isActive {
    opacity: 1;
  }
}

.gradient {
  background-image: var(--gradient-to-top);
}
