.component {
  display: grid;
  grid-template-areas: 'cell';
  position: relative;
  z-index: 0;

  & > * {
    grid-area: cell;
  }

  & > .imageContainer {
    height: max(100vh, 800px);
  }

  & > .containerLayout {
    align-self: end;
    z-index: 2;
    margin-bottom: var(--size-70);

    @media (--viewport-md) {
      margin-bottom: var(--size-50);
    }
  }

  & > .gradient {
    z-index: 1;
    align-self: end;
    width: 100%;
    height: 25%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-20);
  text-align: center;

  & > .scrollIndicator {
    margin-top: var(--size-40);
  }
}

.heading {
  white-space: pre-line;
}

.icon {
  animation: arrowAnimation var(--duration-1500) infinite;

  & > * {
    width: 30px;
    height: 30px;
  }
}

.imageContainer {
  opacity: 0.8;

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.gradient {
  background-image: var(--gradient-to-top);
}
