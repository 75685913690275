.page {
  & > .hero {
    margin-bottom: var(--size-40);
  }
}

.softCta {
  padding-bottom: var(--size-40);
}

.ctaButton {
  padding-bottom: var(--size-40);
  text-align: center;
}

.blocks,
.employeeQuote,
.imageGrid,
.videoText,
.anchorToJobCards {
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);
}

.visualWithQuoteFact,
.facts {
  padding-top: var(--size-60);
  padding-bottom: var(--size-60);
}

.jobCards,
.initiativeList {
  padding-top: var(--size-40);
  padding-bottom: var(--size-100);
}
