.component {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: var(--gutter-responsive) auto var(--gutter-responsive);
  grid-template-rows: auto var(--size-40) repeat(2, 1fr);
  grid-template-areas:
    '.... text  ....'
    '.... ....  ....'
    '.... video video'
    '.... video video';

  @media (--viewport-lg) {
    grid-template-columns: minmax(var(--gutter-responsive), 1fr) repeat(4, minmax(0, calc(var(--size-container-max-width-lg) / 4))) minmax(var(--gutter-responsive), 1fr);
    grid-template-rows: var(--size-70) auto var(--size-40);
    grid-template-areas:
      '.... .... .... ....  ....  ....'
      '.... text text video video ....'
      '.... .... .... video video ....';
  }

  & > * {
    min-width: 0;
  }

  & > .text {
    grid-area: text;
  }

  & > .video {
    grid-area: video;
    align-self: end;
  }

  & > .background {
    grid-column: 1 / span 2;
    grid-row: 1 / span 3;
    z-index: -1;

    @media (--viewport-lg) {
      grid-column: 1 / span 4;
      grid-row: 1 / span 2;
    }
  }
}

.text {
  padding-top: var(--size-40);
  padding-right: var(--size-40);

  @media (--viewport-lg) {
    padding-top: 0;
    padding-right: var(--size-70);
    padding-bottom: var(--size-70);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.innerVideo {
  overflow: hidden;
  isolation: isolate; /* Create new stacking context for Safari otherwise underlying video ignores border-radius */
  border-top-left-radius: var(--border-radius-22);
  border-bottom-left-radius: var(--border-radius-22);

  @media (--viewport-lg) {
    border-radius: var(--border-radius-22);
  }
}

.background {
  background: var(--gradient-to-bottom-left);
  border-top-right-radius: var(--border-radius-22);
  border-bottom-right-radius: var(--border-radius-22);
}
