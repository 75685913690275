.page {
  & > * {
    margin-bottom: var(--size-80);
  }
}

.button {
  display: flex;
  justify-content: center;
}
