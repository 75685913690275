.images {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: 1fr 40px 50px 1fr;
  grid-template-rows: 35px auto 60px auto 60px;

  @media (--viewport-lg) {
    grid-template-columns:
      minmax(var(--gutter), calc((100% - 890px) / 2))
      105px
      1fr
      105px
      35px
      1fr
      minmax(var(--gutter), calc((100% - 890px) / 2));
    grid-template-rows:
      70px
      auto
      135px
      auto
      105px;
  }

  & > * {
    min-width: 0;
  }

  & > .image1 {
    grid-column: 1;
    grid-row: 1 / span 2;

    @media (--viewport-lg) {
      grid-column: 3;
      grid-row: 1 / span 2;
    }
  }

  & > .image2 {
    grid-column: 1 / span 3;
    grid-row: 4 / span 2;
    z-index: 1;

    @media (--viewport-lg) {
      grid-column: 2 / span 4;
      grid-row: 4 / span 2;
    }
  }

  & > .image3 {
    grid-column: 3 / span 2;
    grid-row: 2 / span 3;

    @media (--viewport-lg) {
      grid-column: 5 / span 2;
      grid-row: 2 / span 3;
    }
  }
}

.image1 {
  border-radius: 0 var(--border-radius-22) var(--border-radius-22) 0;

  @media (--viewport-lg) {
    border-radius: var(--border-radius-22);
  }
}

.image2 {
  border-radius: 0 var(--border-radius-22) var(--border-radius-22) 0;

  @media (--viewport-lg) {
    border-radius: var(--border-radius-22);
  }
}

.image3 {
  border-radius: var(--border-radius-22) 0 0 0;

  @media (--viewport-lg) {
    border-radius: var(--border-radius-22);
  }
}

.componentImage {
  overflow: hidden;

  & > * {
    width: 100%;
    height: 100%;
  }
}
