.headingContainer {
  padding: min(50vh, 200px) 0 var(--size-40);
}

.blocks {
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);
}

.cookiePermissions {
  padding-top: var(--size-60);
  padding-bottom: var(--size-60);
}

.heading {
  text-align: center;
}

.componentContent {
  padding-bottom: var(--size-100);
}
