.component {
  z-index: 0;
  position: relative;
  color: var(--color-white);
  font-size: var(--font-size-12);
  font-size: var(--font-size-12-14);
  display: grid;
  grid-template-columns:
    minmax(var(--gutter-responsive), 1fr)
    minmax(0, calc(var(--size-container-max-width-lg) - 50px))
    50px
    minmax(var(--gutter-responsive), 1fr);

  & > * {
    min-width: 0;
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-40);
  }

  & > .topLinks {
    grid-column: 2 / span 3;
    grid-row: 1;

    @media (--viewport-lg) {
      grid-column: 2;
    }
  }

  & > .bottomLinks {
    grid-column: 2;
    grid-row: 3;
    align-self: end;

    @media (--viewport-lg) {
      grid-column: 2;
    }
  }

  & > .background {
    grid-column: 1 / span 3;
    grid-row: 1 / span 3;
    z-index: -1;
    min-height: 340px;
    width: 100%;

    @media (--viewport-lg) {
      grid-column: 1 / span 3;
    }
  }
}

.topLinks {
  padding-top: var(--size-70);
  padding-right: var(--size-70);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--size-40);

  @media (--viewport-lg) {
    flex-direction: row;
    justify-content: space-between;
  }

  & > .logo {
    margin-top: calc(-1 * var(--size-20));
    width: 100%;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);

  @media (--viewport-lg) {
    gap: var(--size-80);
    flex-direction: row;
  }
}

.column {
  display: flex;
  flex-direction: column;

  & > .heading {
    margin-bottom: var(--size-20);

    @media (--viewport-lg) {
      margin-bottom: var(--size-40);
    }
  }

  & > :not(:last-child).link {
    margin-bottom: var(--size-20);
  }
}

.link {
  display: block;
  white-space: nowrap;
  break-inside: avoid;
}

.columns {
  @media (--viewport-lg) {
    column-count: 2;
    column-gap: var(--size-20);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.heading {
  font-size: var(--font-size-24);
  line-height: 1;
}

.bottomLinks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--size-10);
  align-items: baseline;
}

.careersLink {
  font-size: var(--font-size-14);

  @media (--viewport-md) {
    font-size: var(--font-size-16);
  }
}

.extraLinks {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-12);
  line-height: 1;
  opacity: 0.6;

  & > :not(:last-child)::after {
    margin: 0 var(--size-5);
    content: '•';
  }
}

.background {
  background: var(--gradient-to-top-left);
  border-top-right-radius: var(--border-radius-22);
}
