.component {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns:
    minmax(var(--gutter-responsive), 1fr)
    minmax(0, calc(var(--size-container-max-width-lg) - 50px))
    50px
    minmax(var(--gutter-responsive), 1fr);

  & > * {
    grid-row: 1;
    min-width: 0;
  }

  & > .content {
    grid-column: 2;
  }

  & > .background {
    grid-column: 1 / span 3;
    z-index: -1;
    width: 100%;
  }
}

.heading {
  text-align: center;
}

.content {
  padding: var(--size-50) 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .heading {
    margin-bottom: var(--size-70);

    @media (--viewport-md) {
      margin-bottom: var(--size-30);
    }
  }
}

.permissions {
  @media (--viewport-md) {
    column-count: 2;
    column-gap: var(--size-20);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.componentPermission {
  break-inside: avoid;
}

.label {
  display: flex;
  align-items: center;
  gap: var(--size-10);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);

  & > .checkbox {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  &.isDisabled {
    opacity: 0.6;
  }

  &:focus,
  &:hover {
    &:not(.isDisabled):not(.isChecked) {
      & > .checkbox {
        border-width: 6px;
      }
    }
  }
}

.labelContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-5);
}

.labeltitle {
  line-height: 1;
}

.checkbox {
  appearance: none;
  display: inline-block;
  border: 3px solid var(--color-white);
  border-radius: 4px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: border-width var(--duration-150) linear;

  &:checked {
    background-color: var(--color-white);
    background-image: url('/images/icons/check.svg');
  }
}

.background {
  background: var(--gradient-to-bottom-left);
  border-top-right-radius: var(--border-radius-22);
  border-bottom-right-radius: var(--border-radius-22);
}
