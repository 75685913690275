.page {
  & > .hero {
    margin-bottom: var(--size-40);
  }
}

.softCta {
  padding-bottom: var(--size-40);
}

.ctaButton {
  padding-bottom: var(--size-40);
  text-align: center;
}

.componentContent {
  & > .campaignBanner {
    margin-top: var(--size-100);
    margin-bottom: var(--size-100);
  }
}

.anchorToJobCards,
.employeeQuote,
.dataVisualization,
.imageGrid,
.blocks {
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);
}

.facts,
.ctaNextInitiative,
.visualWithQuoteFact {
  padding-top: var(--size-60);
  padding-bottom: var(--size-60);
}

.jobCards,
.disciplineAreaList {
  padding-top: var(--size-40);
  padding-bottom: var(--size-100);
}
