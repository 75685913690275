._rootComponent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: var(--color-blue-500);

  & > * {
    height: 100%;
  }
}

.component {
  & > * {
    width: 100%;
    height: 100%;
  }
}
