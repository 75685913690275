.component {
  & > .qualityWrapperElement {
    width: 100%;
    height: 100%;
  }
}

.qualityWrapperElement {
  & > .video {
    width: 100%;
    height: 100%;
  }
}

.video {
  display: block;
  object-fit: cover;
}
